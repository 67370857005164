@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700;800;900&display=fallback");

@tailwind base;
@tailwind components;
@tailwind utilities;

/* All Site */
[x-cloak=""] {
  display: none;
}

body {
  -webkit-font-smoothing: antialiased;
}
/* Range slider */
:root {
  --range-thumb-size: 36px;
}

input[type="range"] {
  appearance: none;
  background: #ccc;
  border-radius: 3px;
  height: 6px;
  margin-top: (--range-thumb-size - 6px) * 0.5;
  margin-bottom: (--range-thumb-size - 6px) * 0.5;
  --thumb-size: #{--range-thumb-size};
}

input[type="range"]::-webkit-slider-thumb {
  appearance: none;
  -webkit-appearance: none;
  background-color: #000;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 .5v7L12 4zM0 4l4 3.5v-7z' fill='%23FFF' fillRule='nonzero'/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  height: --range-thumb-size;
  width: --range-thumb-size;
}

input[type="range"]::-moz-range-thumb {
  background-color: #000;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 .5v7L12 4zM0 4l4 3.5v-7z' fill='%23FFF' fillRule='nonzero'/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  border: 0;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  height: --range-thumb-size;
  width: --range-thumb-size;
}

input[type="range"]::-ms-thumb {
  background-color: #000;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 .5v7L12 4zM0 4l4 3.5v-7z' fill='%23FFF' fillRule='nonzero'/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  height: --range-thumb-size;
  width: --range-thumb-size;
}

input[type="range"]::-moz-focus-outer {
  border: 0;
}

html {
  scroll-behavior: smooth;
}

.form-input:focus,
.form-textarea:focus,
.form-multiselect:focus,
.form-select:focus,
.form-checkbox:focus,
.form-radio:focus {
  @apply ring-0;
}

/* Hamburger button */
.hamburger svg > *:nth-child(1),
.hamburger svg > *:nth-child(2),
.hamburger svg > *:nth-child(3) {
  transform-origin: center;
  transform: rotate(0deg);
}

.hamburger svg > *:nth-child(1) {
  transition: y 0.1s 0.25s ease-in,
    transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19), opacity 0.1s ease-in;
}

.hamburger svg > *:nth-child(2) {
  transition: transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger svg > *:nth-child(3) {
  transition: y 0.1s 0.25s ease-in,
    transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19),
    width 0.1s 0.25s ease-in;
}

.hamburger.active svg > *:nth-child(1) {
  opacity: 0;
  y: 11;
  transform: rotate(225deg);
  transition: y 0.1s ease-out,
    transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1),
    opacity 0.1s 0.12s ease-out;
}

.hamburger.active svg > *:nth-child(2) {
  transform: rotate(225deg);
  transition: transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger.active svg > *:nth-child(3) {
  y: 11;
  transform: rotate(135deg);
  transition: y 0.1s ease-out,
    transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1),
    width 0.1s ease-out;
}

/* Pulsing animation */
@keyframes pulseLoop {
  0% {
    opacity: 0.15;
    transform: scale(1) translateZ(0);
  }
  30% {
    opacity: 0.15;
  }
  60% {
    opacity: 0;
  }
  80% {
    opacity: 0;
    transform: scale(1.8) translateZ(0);
  }
}
@keyframes pulseMiniLoop {
  0% {
    opacity: 0;
    transform: scale(1) translateZ(0);
  }
  30% {
    opacity: 0.3;
  }
  50% {
    opacity: 0.3;
  }
  80% {
    opacity: 0;
    transform: scale(3) translateZ(0);
  }
}
.pulse {
  transform: scale(1);
  opacity: 0;
  transform-origin: center;
  animation: pulseLoop 10000ms linear infinite;
}
.pulse-mini {
  animation: pulseMiniLoop 6000ms linear infinite;
}
.pulse-1 {
  animation-delay: -3000ms;
}
.pulse-2 {
  animation-delay: -6000ms;
}

/* Animations delay */
.animation-delay-500 {
  animation-delay: 500ms !important;
}

.animation-delay-1000 {
  animation-delay: 1000ms !important;
}

.translate-z-0 {
  transform: translateZ(0);
}

/* Custom AOS animations */
[data-aos="zoom-y-out"] {
  transform: scaleX(1.03);
  opacity: 0;
  transition-property: transform, opacity;
}

@media screen {
  html:not(.no-js) [data-aos="fade-up"] {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }

  html:not(.no-js) [data-aos="fade-down"] {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }

  html:not(.no-js) [data-aos="fade-right"] {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }

  html:not(.no-js) [data-aos="fade-left"] {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }

  html:not(.no-js) [data-aos="fade-up-right"] {
    -webkit-transform: translate3d(-10px, 10px, 0);
    transform: translate3d(-10px, 10px, 0);
  }

  html:not(.no-js) [data-aos="fade-up-left"] {
    -webkit-transform: translate3d(10px, 10px, 0);
    transform: translate3d(10px, 10px, 0);
  }

  html:not(.no-js) [data-aos="fade-down-right"] {
    -webkit-transform: translate3d(-10px, -10px, 0);
    transform: translate3d(-10px, -10px, 0);
  }

  html:not(.no-js) [data-aos="fade-down-left"] {
    -webkit-transform: translate3d(10px, -10px, 0);
    transform: translate3d(10px, -10px, 0);
  }

  html:not(.no-js) [data-aos="zoom-in-up"] {
    -webkit-transform: translate3d(0, 10px, 0) scale(0.6);
    transform: translate3d(0, 10px, 0) scale(0.6);
  }

  html:not(.no-js) [data-aos="zoom-in-down"] {
    -webkit-transform: translate3d(0, -10px, 0) scale(0.6);
    transform: translate3d(0, -10px, 0) scale(0.6);
  }

  html:not(.no-js) [data-aos="zoom-in-right"] {
    -webkit-transform: translate3d(-10px, 0, 0) scale(0.6);
    transform: translate3d(-10px, 0, 0) scale(0.6);
  }

  html:not(.no-js) [data-aos="zoom-in-left"] {
    -webkit-transform: translate3d(10px, 0, 0) scale(0.6);
    transform: translate3d(10px, 0, 0) scale(0.6);
  }

  html:not(.no-js) [data-aos="zoom-out-up"] {
    -webkit-transform: translate3d(0, 10px, 0) scale(1.2);
    transform: translate3d(0, 10px, 0) scale(1.2);
  }

  html:not(.no-js) [data-aos="zoom-out-down"] {
    -webkit-transform: translate3d(0, -10px, 0) scale(1.2);
    transform: translate3d(0, -10px, 0) scale(1.2);
  }

  html:not(.no-js) [data-aos="zoom-out-right"] {
    -webkit-transform: translate3d(-10px, 0, 0) scale(1.2);
    transform: translate3d(-10px, 0, 0) scale(1.2);
  }

  html:not(.no-js) [data-aos="zoom-out-left"] {
    -webkit-transform: translate3d(10px, 0, 0) scale(1.2);
    transform: translate3d(10px, 0, 0) scale(1.2);
  }
}

/* Switch element */
.form-switch {
  @apply relative select-none;
  width: 68px;
}

.form-switch label {
  @apply block overflow-hidden cursor-pointer rounded;
  height: 38px;
}

.form-switch label > span:first-child {
  @apply absolute block rounded shadow;
  width: 30px;
  height: 30px;
  top: 4px;
  left: 4px;
  right: 50%;
  transition: all 0.15s ease-out;
}

.form-switch input[type="checkbox"]:checked + label {
  @apply bg-blue-600;
}

.form-switch input[type="checkbox"]:checked + label > span:first-child {
  left: 34px;
}

/* Typography */
.h1 {
  @apply text-4xl font-extrabold leading-tight tracking-tighter;
}

.h2 {
  @apply text-3xl font-extrabold leading-tight tracking-tighter;
}

.h3 {
  @apply text-3xl font-bold leading-tight;
}

.h4 {
  @apply text-2xl font-bold leading-snug tracking-tight;
}

@screen md {
  .h1 {
    @apply text-5xl;
  }

  .h2 {
    @apply text-4xl;
  }
}

/* Buttons */
.btn,
.btn-sm {
  @apply font-medium inline-flex items-center justify-center border border-transparent rounded leading-snug transition duration-150 ease-in-out;
}

.btn {
  @apply px-8 py-3 shadow-lg;
}

.btn-sm {
  @apply px-4 py-2 shadow;
}

/* Forms */
.form-input,
.form-textarea,
.form-multiselect,
.form-select,
.form-checkbox,
.form-radio {
  @apply bg-white border border-gray-300 focus:border-gray-500;
}

.form-input,
.form-textarea,
.form-multiselect,
.form-select,
.form-checkbox {
  @apply rounded;
}

.form-input,
.form-textarea,
.form-multiselect,
.form-select {
  @apply py-3 px-4;
}

.form-input,
.form-textarea {
  @apply placeholder-gray-500;
}

.form-select {
  @apply pr-10;
}

.form-checkbox,
.form-radio {
  @apply text-gray-800 rounded-sm;
}

._gmxfZ2BpOHxa6nWwqBB {
  margin-right: 0.5rem;
}
.rD4HtsUG_hahmbh2Kj09 {
  margin-bottom: 0.5rem;
}
.Ljy4iPgx864jXhJbnInL {
  margin-left: 0.75rem;
}
._9OKVeTXzfSwD_NYO6_G {
  margin-bottom: 1rem;
}
.ttxtqsLWp2pFRX8yUvWd {
  display: block;
}
.kqgYncRJQ7spwKfig6It {
  display: flex;
}
.ay0ziTPUL4Ag5d1DkSY7 {
  display: inline-flex;
}
.i0EfZzmTLElZVOble53D {
  display: grid;
}
.RRXFBumaW2SHdseZaWm6 {
  height: 1.25rem;
}
.xm7OMZMei4gQKwhsRmfw {
  height: 0.125rem;
}
.A5NAVdB7T15ak0OJxDs2 {
  height: 0;
}
.eVNhx7m5tjSVbfYQzDdT {
  height: 1rem;
}
._pwSRUXRHN5bHphyTRKz {
  height: 2rem;
}
._EiPZYgyUypIYjFtegNh {
  height: 2.5rem;
}
.jCISvWkW5oStPH6Wrb_H {
  width: 100%;
}
.eUuXwBkW5W4__eatjSfd {
  width: 1.25rem;
}
.kbeH5ty3CtPKxXm5TXph {
  width: 1rem;
}
._vZPglRSyqi4oTXg5L1_ {
  width: 2rem;
}
.bt0MUzUgiWUaZjB4iWGh {
  width: 2.5rem;
}
.tnci_tQZ_0ng1kTqTwHZ {
  max-width: 28rem;
}
.B_Mg78Zl0_AuSOcVLMGS {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.__9yfFbPJuEYWBMy4kA9 {
  align-items: flex-start;
}
.neyUwteEn7DOg9pBSJJE {
  align-items: center;
}
._WclR59Ji8jwfmjPtOei {
  justify-content: center;
}
._T15kfOPGkvwZnqhqKce {
  justify-content: space-between;
}
.FLfl_xS1XCloWU2wRi87 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
  margin-top: calc(1rem * (1 - var(--tw-space-y-reverse)));
}
.nLBh_4HJncWRXQXC22Af > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-left: calc(0px * (1 - var(--tw-space-x-reverse)));
  margin-right: calc(0px * var(--tw-space-x-reverse));
}
.__Iv72FadFpzwcpFl5_5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-bottom: calc(0.75rem * var(--tw-space-y-reverse));
  margin-top: calc(0.75rem * (1 - var(--tw-space-y-reverse)));
}
._LFTxJkvRWfI_UdU8mi1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-left: calc(-1rem * (1 - var(--tw-space-x-reverse)));
  margin-right: calc(-1rem * var(--tw-space-x-reverse));
}
.sXryjJtpSuepHYRQvdmw > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 0;
  border-left-width: calc(1px * (1 - var(--tw-divide-x-reverse)));
  border-right-width: calc(1px * var(--tw-divide-x-reverse));
}
._Qk4_E9_iLqcHsRZZ4ge {
  border-radius: 0.5rem;
}
.YPSoR6AXtPgkmylUmcbT {
  border-radius: 0.25rem;
}
.n8e6ORKgPTnY6zgs5HS7 {
  border-radius: 9999px;
}
.PWreZZgitgAm_Nv4Noh9 {
  border-width: 1px;
}
.bQPRusdUv2Wr50Lq2KOG {
  border-width: 2px;
}
.pxHuWvF853ck68OLN6ef {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
}
.T_tFENbpK8DMDNjQRyQa {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}
._WlCPLL0LIv8ViOuJIFs {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}
.yjGyQxv8jnYk9_MGMqLN {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
.vyuwQLkFO7jUAv3Xr23z {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}
.jCHKuJ3G7rklx_LiAfbf {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}
._4wtDMQ2AdJOlYvml5sL {
  padding: 0.625rem;
}
.Y34SQMYAJVIAdOFKA3gG {
  padding: 0.5rem;
}
.veFXkDzfJN473U3ycrV8 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.v93wvhik4Hi__dVhkpJC {
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
}
._gKcj49wZgnwx1LpcJi6 {
  padding-bottom: 0.625rem;
  padding-top: 0.625rem;
}
.bFARDnno0HUtfhktTXfR {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.zhRMeqbg7JsftloqW_W6 {
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}
.o2Ul2ehO22E_G9Nn7M4G {
  padding-right: 0.75rem;
}
._mzhSDAYlA3W05MgSqAf {
  padding-left: 0.75rem;
}
._F_1gdhzusC6tSOWHtx_ {
  text-align: center;
}
.uyo8h_4Kh1IoUwm8bwJI {
  font-size: 1.25rem;
  line-height: 1.75rem;
}
.MxG1ClE4KPrIvlL5_Q5x {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
._e063bssp_1bldcJ6kR0 {
  font-size: 1.5rem;
  line-height: 2rem;
}
.G_Z_YOvXu8grS0GsAGg_ {
  font-size: 1.875rem;
  line-height: 2.25rem;
}
._WfIfkoGCi0vvUrnNs4M {
  font-weight: 700;
}
._A6LflweZRUwrcL6M2Tk {
  font-weight: 500;
}
.XdjN1uxS_rsa3F90ox40 {
  font-weight: 300;
}
.LYMps1kO2vF8HBymW3az {
  font-weight: 600;
}
.P2kov_od1FPjxxII24T5 {
  font-weight: 800;
}
.MxQqv3Z913orO6JQGGbH {
  line-height: 1;
}
.marR_sCaF_d1KewmkXGX {
  letter-spacing: -0.025em;
}
.g3OYBOqwXUEW4dRGogkH {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}
.K1PPCJwslha8GUIvV_Cr {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}
.wP9HMsqy6b96l2HBRbgb {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.DpMPWwlSESiYA8EE1xKM:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}
._xQT_qSXfwWf6ZhwRle4:hover {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}
._5zvlMLkN1qETxEl3IhT:hover {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}
.m_8FxTcpOfmK___hAaJ6:focus {
  z-index: 10;
}
.qHIOIw8TObHgD3VvKa5x:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
._FONMPVaCsLFJJGDaaIL:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
}
._bKyZ1er5YE_NnrwOCm9:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(229 231 235 / var(--tw-ring-opacity));
}
._neKTiNR9ODo9dbROzCM ._BIVSYBXQUqEf_ltPrSk {
  --tw-border-opacity: 1;
  border-color: rgb(75 85 99 / var(--tw-border-opacity));
}
._neKTiNR9ODo9dbROzCM .zlFmyfujKXCLCPyPEOIS {
  --tw-bg-opacity: 1;
  background-color: rgb(17 24 39 / var(--tw-bg-opacity));
}
._neKTiNR9ODo9dbROzCM ._cpMMPjFQqjJu4i0Puod {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity));
}
._neKTiNR9ODo9dbROzCM ._t2wg7hRcyKsNN8CSSeU {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
}
._neKTiNR9ODo9dbROzCM .a0Ed69aMSu0vgf4oysz0 {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
._neKTiNR9ODo9dbROzCM .eCx_6PNzncAD5yo7Qcic {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}
._neKTiNR9ODo9dbROzCM ._Rz9TooiK_4jTN_Ub8Gm {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity));
}
._neKTiNR9ODo9dbROzCM ._DJ2tfp6E9c_teMKVD3z::-moz-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(156 163 175 / var(--tw-placeholder-opacity));
}
._neKTiNR9ODo9dbROzCM ._DJ2tfp6E9c_teMKVD3z:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(156 163 175 / var(--tw-placeholder-opacity));
}
._neKTiNR9ODo9dbROzCM ._DJ2tfp6E9c_teMKVD3z::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(156 163 175 / var(--tw-placeholder-opacity));
}
._neKTiNR9ODo9dbROzCM .apRlbPZRJ4JWA5RVdURT {
  --tw-ring-offset-color: #1f2937;
}
._neKTiNR9ODo9dbROzCM ._OovBxfPdK7Rjv2nh2Ot:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
}
._neKTiNR9ODo9dbROzCM .DTyjKhtXBNaebZa5L0l9:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
._neKTiNR9ODo9dbROzCM .__8kBLtrR_iuU2wW25Lp:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(55 65 81 / var(--tw-ring-opacity));
}
@media (min-width: 640px) {
  .U4m6yFbNbl_ea9gVo2Uw {
    display: flex;
  }
  ._uGk0TKCucyvLBoD8KyB > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-left: calc(1rem * (1 - var(--tw-space-x-reverse)));
    margin-right: calc(1rem * var(--tw-space-x-reverse));
  }
  ._rT9gacXyvSO1fCjLFak > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
    margin-top: calc(0px * (1 - var(--tw-space-y-reverse)));
  }
  ._Z_jxLj6dxcyc0eF0Vas {
    padding-left: 0;
    padding-right: 0;
  }
  .TAT4_3Nxmv9bkNq4MrcJ {
    padding-right: 1.25rem;
  }
  .jpms_DMv54zgMeTI0eRM {
    padding-left: 1.25rem;
  }
}
@media (min-width: 768px) {
  .Z6U5SEO6oB8k0BUE6Lpu > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
    margin-top: calc(1.5rem * (1 - var(--tw-space-y-reverse)));
  }
}
@media (min-width: 1024px) {
  .F_2Dre6FGlusaaY9LAdu {
    margin-bottom: 2rem;
  }
  .fxZTOfUDTmJhXUzuvw8s {
    height: 100vh;
  }
  .bkn9u4HE5LeLYsQ0a0MO {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .PXkJjw7zOhdtCh3e_PWh {
    padding-bottom: 0;
    padding-top: 0;
  }
}
@media (min-width: 1280px) {
  .VYwWi9PJwsepsh9PnOaK {
    max-width: 36rem;
  }
  ._amhxXWUowTlPtqMPd3N {
    font-size: 3rem;
    line-height: 1;
  }
}
