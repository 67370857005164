@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700;800;900&display=fallback");

@tailwind base;
@tailwind components;
@tailwind utilities;

/* All Site */
[x-cloak=""] {
  display: none;
}

body {
  -webkit-font-smoothing: antialiased;
}
/* Range slider */
:root {
  --range-thumb-size: 36px;
}

input[type="range"] {
  appearance: none;
  background: #ccc;
  border-radius: 3px;
  height: 6px;
  margin-top: (--range-thumb-size - 6px) * 0.5;
  margin-bottom: (--range-thumb-size - 6px) * 0.5;
  --thumb-size: #{--range-thumb-size};
}

input[type="range"]::-webkit-slider-thumb {
  appearance: none;
  -webkit-appearance: none;
  background-color: #000;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 .5v7L12 4zM0 4l4 3.5v-7z' fill='%23FFF' fillRule='nonzero'/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  height: --range-thumb-size;
  width: --range-thumb-size;
}

input[type="range"]::-moz-range-thumb {
  background-color: #000;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 .5v7L12 4zM0 4l4 3.5v-7z' fill='%23FFF' fillRule='nonzero'/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  border: 0;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  height: --range-thumb-size;
  width: --range-thumb-size;
}

input[type="range"]::-ms-thumb {
  background-color: #000;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 .5v7L12 4zM0 4l4 3.5v-7z' fill='%23FFF' fillRule='nonzero'/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  height: --range-thumb-size;
  width: --range-thumb-size;
}

input[type="range"]::-moz-focus-outer {
  border: 0;
}

html {
  scroll-behavior: smooth;
}

.form-input:focus,
.form-textarea:focus,
.form-multiselect:focus,
.form-select:focus,
.form-checkbox:focus,
.form-radio:focus {
  @apply ring-0;
}

/* Hamburger button */
.hamburger svg > *:nth-child(1),
.hamburger svg > *:nth-child(2),
.hamburger svg > *:nth-child(3) {
  transform-origin: center;
  transform: rotate(0deg);
}

.hamburger svg > *:nth-child(1) {
  transition: y 0.1s 0.25s ease-in,
    transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19), opacity 0.1s ease-in;
}

.hamburger svg > *:nth-child(2) {
  transition: transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger svg > *:nth-child(3) {
  transition: y 0.1s 0.25s ease-in,
    transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19),
    width 0.1s 0.25s ease-in;
}

.hamburger.active svg > *:nth-child(1) {
  opacity: 0;
  y: 11;
  transform: rotate(225deg);
  transition: y 0.1s ease-out,
    transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1),
    opacity 0.1s 0.12s ease-out;
}

.hamburger.active svg > *:nth-child(2) {
  transform: rotate(225deg);
  transition: transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger.active svg > *:nth-child(3) {
  y: 11;
  transform: rotate(135deg);
  transition: y 0.1s ease-out,
    transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1),
    width 0.1s ease-out;
}

/* Pulsing animation */
@keyframes pulseLoop {
  0% {
    opacity: 0.15;
    transform: scale(1) translateZ(0);
  }
  30% {
    opacity: 0.15;
  }
  60% {
    opacity: 0;
  }
  80% {
    opacity: 0;
    transform: scale(1.8) translateZ(0);
  }
}
@keyframes pulseMiniLoop {
  0% {
    opacity: 0;
    transform: scale(1) translateZ(0);
  }
  30% {
    opacity: 0.3;
  }
  50% {
    opacity: 0.3;
  }
  80% {
    opacity: 0;
    transform: scale(3) translateZ(0);
  }
}
.pulse {
  transform: scale(1);
  opacity: 0;
  transform-origin: center;
  animation: pulseLoop 10000ms linear infinite;
}
.pulse-mini {
  animation: pulseMiniLoop 6000ms linear infinite;
}
.pulse-1 {
  animation-delay: -3000ms;
}
.pulse-2 {
  animation-delay: -6000ms;
}

/* Animations delay */
.animation-delay-500 {
  animation-delay: 500ms !important;
}

.animation-delay-1000 {
  animation-delay: 1000ms !important;
}

.translate-z-0 {
  transform: translateZ(0);
}

/* Custom AOS animations */
[data-aos="zoom-y-out"] {
  transform: scaleX(1.03);
  opacity: 0;
  transition-property: transform, opacity;
}

@media screen {
  html:not(.no-js) [data-aos="fade-up"] {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }

  html:not(.no-js) [data-aos="fade-down"] {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }

  html:not(.no-js) [data-aos="fade-right"] {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }

  html:not(.no-js) [data-aos="fade-left"] {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }

  html:not(.no-js) [data-aos="fade-up-right"] {
    -webkit-transform: translate3d(-10px, 10px, 0);
    transform: translate3d(-10px, 10px, 0);
  }

  html:not(.no-js) [data-aos="fade-up-left"] {
    -webkit-transform: translate3d(10px, 10px, 0);
    transform: translate3d(10px, 10px, 0);
  }

  html:not(.no-js) [data-aos="fade-down-right"] {
    -webkit-transform: translate3d(-10px, -10px, 0);
    transform: translate3d(-10px, -10px, 0);
  }

  html:not(.no-js) [data-aos="fade-down-left"] {
    -webkit-transform: translate3d(10px, -10px, 0);
    transform: translate3d(10px, -10px, 0);
  }

  html:not(.no-js) [data-aos="zoom-in-up"] {
    -webkit-transform: translate3d(0, 10px, 0) scale(0.6);
    transform: translate3d(0, 10px, 0) scale(0.6);
  }

  html:not(.no-js) [data-aos="zoom-in-down"] {
    -webkit-transform: translate3d(0, -10px, 0) scale(0.6);
    transform: translate3d(0, -10px, 0) scale(0.6);
  }

  html:not(.no-js) [data-aos="zoom-in-right"] {
    -webkit-transform: translate3d(-10px, 0, 0) scale(0.6);
    transform: translate3d(-10px, 0, 0) scale(0.6);
  }

  html:not(.no-js) [data-aos="zoom-in-left"] {
    -webkit-transform: translate3d(10px, 0, 0) scale(0.6);
    transform: translate3d(10px, 0, 0) scale(0.6);
  }

  html:not(.no-js) [data-aos="zoom-out-up"] {
    -webkit-transform: translate3d(0, 10px, 0) scale(1.2);
    transform: translate3d(0, 10px, 0) scale(1.2);
  }

  html:not(.no-js) [data-aos="zoom-out-down"] {
    -webkit-transform: translate3d(0, -10px, 0) scale(1.2);
    transform: translate3d(0, -10px, 0) scale(1.2);
  }

  html:not(.no-js) [data-aos="zoom-out-right"] {
    -webkit-transform: translate3d(-10px, 0, 0) scale(1.2);
    transform: translate3d(-10px, 0, 0) scale(1.2);
  }

  html:not(.no-js) [data-aos="zoom-out-left"] {
    -webkit-transform: translate3d(10px, 0, 0) scale(1.2);
    transform: translate3d(10px, 0, 0) scale(1.2);
  }
}

/* Switch element */
.form-switch {
  @apply relative select-none;
  width: 68px;
}

.form-switch label {
  @apply block overflow-hidden cursor-pointer rounded;
  height: 38px;
}

.form-switch label > span:first-child {
  @apply absolute block rounded shadow;
  width: 30px;
  height: 30px;
  top: 4px;
  left: 4px;
  right: 50%;
  transition: all 0.15s ease-out;
}

.form-switch input[type="checkbox"]:checked + label {
  @apply bg-blue-600;
}

.form-switch input[type="checkbox"]:checked + label > span:first-child {
  left: 34px;
}

/* Typography */
.h1 {
  @apply text-4xl font-extrabold leading-tight tracking-tighter;
}

.h2 {
  @apply text-3xl font-extrabold leading-tight tracking-tighter;
}

.h3 {
  @apply text-3xl font-bold leading-tight;
}

.h4 {
  @apply text-2xl font-bold leading-snug tracking-tight;
}

@screen md {
  .h1 {
    @apply text-5xl;
  }

  .h2 {
    @apply text-4xl;
  }
}

/* Buttons */
.btn,
.btn-sm {
  @apply font-medium inline-flex items-center justify-center border border-transparent rounded leading-snug transition duration-150 ease-in-out;
}

.btn {
  @apply px-8 py-3 shadow-lg;
}

.btn-sm {
  @apply px-4 py-2 shadow;
}

/* Forms */
.form-input,
.form-textarea,
.form-multiselect,
.form-select,
.form-checkbox,
.form-radio {
  @apply bg-white border border-gray-300 focus:border-gray-500;
}

.form-input,
.form-textarea,
.form-multiselect,
.form-select,
.form-checkbox {
  @apply rounded;
}

.form-input,
.form-textarea,
.form-multiselect,
.form-select {
  @apply py-3 px-4;
}

.form-input,
.form-textarea {
  @apply placeholder-gray-500;
}

.form-select {
  @apply pr-10;
}

.form-checkbox,
.form-radio {
  @apply text-gray-800 rounded-sm;
}
